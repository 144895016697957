
  import { defineComponent, ref, computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n/index';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';

  export default defineComponent({
    name: 'property-types-filter',
    emit: ['property-type-selected', 'reset'],
    components: {},
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const store = useStore();
      const selectedItems = ref<any>({
        type: -1,
        propertyCategoryId: '',
        adTypeId: '',
      });
      const propertyTypesCategories = ref<any>([]);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const onSubmit = () => {
        emit('property-type-selected', selectedItems);
      };
      const onReset = () => {
        selectedItems.value = {
          type: -1,
          propertyCategoryId: '',
          adTypeId: '',
        };
        emit('reset', selectedItems.value);
      };

      watch(
        () => selectedItems.value.adTypeId,
        async () => {
          if (selectedItems.value.adTypeId) {
            await store.dispatch(
              Actions.GET_ALL_PROPERTY_CATEGORIES_BY_AD_TYPE_ID,
              { adType: selectedItems.value.adTypeId }
            );

            propertyTypesCategories.value =
              store.getters.propertyAllCategoryList;
          }
        }
      );

      await store.dispatch(Actions.GET_ALL_AD_TYPES);

      reinitializeComponents();

      return {
        adTypes: computed(() => store.getters.adTypesAllList),
        propertyTypesCategories,
        onSubmit,
        onReset,
        translate,
        selectedItems,
      };
    },
  });
