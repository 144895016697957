
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import PropertyTypesFilter from '@/components/dropdown/PropertyTypesFilter.vue';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';

  export default defineComponent({
    name: 'property-types-listing',
    components: {
      Datatable,
      PropertyTypesFilter,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('propertyTypesListing'), [
          translate('ads'),
        ]);
        reinitializeComponents();
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_PROPERTY_TYPES_STORE);
      });

      await store.dispatch(Actions.GET_PROPERTY_TYPES);
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('idColumn'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('propertyTypeNameEn'),
          key: 'titleEn',
          sortable: true,
        },
        {
          name: translate('propertyTypeNameAr'),
          key: 'titleAr',
          sortable: true,
        },
        {
          name: translate('propertyCategory'),
          key: 'propertyCategory',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'isActive',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);

      const editProperty = (id) => {
        router.push({ name: 'property-types-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_PROPERTY_TYPE_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_PROPERTY_TYPES);
        loading.value = false;
      };

      const onTypeChange = async (values) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_PROPERTY_TYPE_CURRENT_PAGE, 1);

        await store.dispatch(
          Actions.UPDATE_PROPERTY_TYPES_FILTER,
          values.value
        );
        await store.dispatch(Actions.GET_PROPERTY_TYPES);
        loading.value = false;
      };
      const onReset = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_PROPERTY_TYPES_FILTER, value);
        await store.dispatch(Actions.GET_PROPERTY_TYPES);
        loading.value = false;
      };
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.propertyTypesList),
        tableHeader,
        translate,
        totalItems: computed(() => store.getters.propertyTypesCount),
        pageChanged,
        goTo,
        editProperty,
        loading,
        can,
        onTypeChange,
        onReset,
      };
    },
  });
